import {
    SETTINGS_LOADING,
    SETTINGS_LOADED,
    SETTINGS_ERROR,
    SETTINGS_UPDATED,
} from '../actions/SettingsActions';

const initialState = {
    data: {},
    loading: false,
    error: null
};

const SettingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SETTINGS_LOADING:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case SETTINGS_LOADED:
        case SETTINGS_UPDATED:
            return {
                data: {...state.data, ...action.payload},
                loading: false,
                error: null,
            }
        case SETTINGS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        default:
            return state;
    }
}

export default SettingsReducer;

