import { combineReducers } from "redux";
import LoginReducer from "./LoginReducer";
import RegisterReducer from "./RegisterReducer";
import UserReducer from "./UserReducer";
import LayoutReducer from "./LayoutReducer";
import CourseReducer from "./CourseReducer";
import CategoryReducer from "./CategoryReducer";
import CourseBlockReducer from "./CourseBlockReducer";
import SettingsReducer from "./SettingsReducer";
import SchoolReducer from "./SchoolReducer";

const RootReducer = combineReducers({
  login: LoginReducer,
  register: RegisterReducer,
  user: UserReducer,
  layout: LayoutReducer,
  courses: CourseReducer,
  blocks: CourseBlockReducer,
  categories: CategoryReducer,
  settings: SettingsReducer,
  school: SchoolReducer,
});

export default RootReducer;
