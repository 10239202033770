import "../styles/_app.scss";
import React, {Suspense} from "react";
import {Provider} from "react-redux";
import {Router} from "react-router-dom";
import EgretTheme from "./EgretLayout/EgretTheme/EgretTheme";
import AppContext from "./appContext";
import history from "history.js";
import routes from "./RootRoutes";
import {Store} from "./redux/Store";
import Auth from "./auth/Auth";
import EgretLayout from "./EgretLayout/EgretLayout";
import AuthGuard from "./auth/AuthGuard";
import {SnackbarProvider} from 'notistack';
import School from "./school/School";
import {Helmet} from "react-helmet";
import {useExchange, SimpleLoader} from "@grainclub/frontend-shared-components";

const App = () => {

  const token = useExchange();

  if (false === token) {
    return <SimpleLoader/>;
  }

  return (
    <AppContext.Provider value={{routes}}>
      <Provider store={Store}>
        <Helmet>
          <title>Grain Club</title>
        </Helmet>
        <EgretTheme>
          <Auth>
            <School>
              <Router history={history}>
                <AuthGuard>
                  <SnackbarProvider maxSnack={3} autoHideDuration={1000}>
                    <Suspense fallback={""}>
                      <EgretLayout/>
                    </Suspense>
                  </SnackbarProvider>
                </AuthGuard>
              </Router>
            </School>
          </Auth>
        </EgretTheme>
      </Provider>
    </AppContext.Provider>
  );
};

export default App;
