import {
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGIN_LOADING,
  RESET_PASSWORD, LOGIN_EMAIL_NEEDED, LOGIN_RESET, LOGIN_SMS_SENT, LOGIN_SMS_BAD_CODE
} from "../actions/LoginActions";

const initialState = {
  success: false,
  loading: false,
  emailNeeded: false,
  oAuthPayload: {},
  oAuthProvider: null,
  smsLogin:{},
  error: {
    username: null,
    password: null
  }
};

const LoginReducer = function(state = initialState, action) {
  switch (action.type) {
    case LOGIN_LOADING: {
      return {
        ...state,
        loading: true
      };
    }
    case LOGIN_SUCCESS: {
      return {
        ...state,
        success: true,
        loading: false
      };
    }
    case RESET_PASSWORD: {
      return {
        ...state,
        success: true,
        loading: false
      };
    }
    case LOGIN_ERROR: {
      return {
        ...state,
        success: false,
        loading: false,
        error: action.error
      };
    }
    case LOGIN_EMAIL_NEEDED: {
      return {
        ...state,
        success: false,
        loading: false,
        emailNeeded: true,
        oAuthPayload: action.payload,
        oAuthProvider: action.provider
      };
    }
    case LOGIN_RESET: {
      return {...initialState};
    }
    case LOGIN_SMS_SENT:{
      return {
        ...state,
        success: false,
        loading: false,
        smsLogin: action.data
      };
    }
    case LOGIN_SMS_BAD_CODE:{
      return {
        ...state,
        success: false,
        loading: false,
        smsLogin: {...state.smsLogin, error: true}
      };
    }
    default: {
      return state;
    }
  }
};

export default LoginReducer;
