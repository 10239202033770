import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {PropTypes} from "prop-types";
import {setUserData} from "../redux/actions/UserActions";
import {SimpleLoader, jwtAuthService} from "@grainclub/frontend-shared-components";

class Auth extends Component {
  state = {};

  constructor(props) {
    super(props);
    this.state = {
      authChecked: false,
    };
  }

  componentDidMount() {
    this.checkJwtAuth();
  }

  checkJwtAuth = () => {
      jwtAuthService.loginWithToken().then(user => {
        this.props.setUserData(user);
        this.setState({authChecked: true});
      }).catch(() => {
        this.props.setUserData(null);
        this.setState({authChecked: true});
      });
  };

  render() {
    const {children} = this.props;
    const {authChecked} = this.state;
    return authChecked ? <Fragment>{children}</Fragment> : <SimpleLoader/>;
  }
}

const mapStateToProps = state => ({
  setUserData: PropTypes.func.isRequired,
  login: state.login
});

export default connect(
  mapStateToProps,
  {setUserData}
)(Auth);
