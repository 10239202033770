import axios from "axios";
export const LOAD_ALL_COURSE_BLOCKS = "LOAD_ALL_COURSE_BLOCKS";
export const LOAD_COURSE_BLOCK = "LOAD_COURSE_BLOCK";
export const COURSE_BLOCK_LOADING = "COURSE_BLOCK_LOADING";
export const COURSE_BLOCK_ERROR = "COURSE_BLOCK_ERROR";
export const ADD_COURSE_BLOCK = "ADD_COURSE_BLOCK";
export const UPDATE_COURSE_BLOCK = "UPDATE_COURSE_BLOCK";
export const DELETE_COURSE_BLOCK = "DELETE_COURSE_BLOCK";

export const getCourseBlocks = (courseId) => dispatch => {
    dispatch({
        type: COURSE_BLOCK_LOADING
    });

    return axios.get(`${process.env.REACT_APP_API_URL}/en/api/events/${courseId}/`).then(res => {
        dispatch({
            type: LOAD_ALL_COURSE_BLOCKS,
            payload: res.data.units
        });
    }).catch( error => {
        dispatch({
            type: COURSE_BLOCK_ERROR,
            error: error.message || error
        });
    });
};

export const getCourseBlock = (courseId, id) => dispatch => {
    dispatch({
        type: COURSE_BLOCK_LOADING
    });

    return axios.get(`${process.env.REACT_APP_API_URL}/en/api/events/${courseId}/units/${id}/`).then(res => {
        dispatch({
            type: LOAD_COURSE_BLOCK,
            payload: res.data
        });
    }).catch( error => {
        dispatch({
            type: COURSE_BLOCK_ERROR,
            error: error.message || error
        });

        return Promise.reject(error);
    });
};


export const addCourseBlock = (courseId, data) => dispatch => {
    dispatch({
        type: COURSE_BLOCK_LOADING
    });

    return axios.post(`${process.env.REACT_APP_API_URL}/en/api/events/${courseId}/create_unit/`,data).then(res => {
        dispatch({
            type: ADD_COURSE_BLOCK,
            payload: res.data
        });
    }).catch( error => {
        dispatch({
            type: COURSE_BLOCK_ERROR,
            error: error.message || error
        });

        return Promise.reject(error);
    });
};


export const updateCourseBlock = (courseId, blockId, data) => dispatch => {
    dispatch({
        type: COURSE_BLOCK_LOADING
    });

    return axios.patch(`${process.env.REACT_APP_API_URL}/en/api/events/${courseId}/units/${blockId}/`,data).then(res => {
        dispatch({
            type: ADD_COURSE_BLOCK,
            payload: res.data
        });
    }).catch( error => {
        dispatch({
            type: COURSE_BLOCK_ERROR,
            error: error.message || error
        });
    });
};


export const deleteCourseBlock = (courseId, blockId) => dispatch => {
    dispatch({
        type: COURSE_BLOCK_LOADING
    });

    return axios.delete(`${process.env.REACT_APP_API_URL}/en/api/events/${courseId}/units/${blockId}/`).then(res => {
        dispatch({
            type: DELETE_COURSE_BLOCK,
        });
    }).catch( error => {
        dispatch({
            type: COURSE_BLOCK_ERROR,
            error: error.message || error
        });

        return Promise.reject(error);
    });

};