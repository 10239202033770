import axios from "axios"

export const LOAD_ALL_COURSES = "LOAD_ALL_COURSES";
export const LOAD_COURSE_BY_ID = "LOAD_COURSE_BY_ID";
export const CREATE_COURSE = "CREATE_COURSE";
export const UPDATE_COURSE = "CREATE_COURSE";
export const COURSES_LOADING = "COURSES_LOADING";
export const COURSE_UPLOAD_IMAGE = "COURSE_UPLOAD_IMAGE";
export const COURSES_LOADING_ERROR = "COURSES_LOADING_ERROR";

export const getCourses = (filter = {}) => dispatch => {
    dispatch({
        type: COURSES_LOADING
    });

    return axios.get(`${process.env.REACT_APP_API_URL}/en/api/events/`,{ params: filter}).then(res => {
        dispatch({
            type: LOAD_ALL_COURSES,
            payload: res.data
        });
    }).catch( error => {
        dispatch({
            type: COURSES_LOADING_ERROR,
            error: error.message || error
        });
    });
};

export const getCourse = (id) => dispatch => {
    dispatch({
        type: COURSES_LOADING
    });

    return axios.get(`${process.env.REACT_APP_API_URL}/en/api/events/${id}/`).then(res => {
        dispatch({
            type: LOAD_COURSE_BY_ID,
            payload: res.data
        });
    }).catch( error => {

        dispatch({
            type: COURSES_LOADING_ERROR,
            error: error.message || error
        });
        
        return Promise.reject(error);
    });
};

export const createCourse = (data) => dispatch => {
    dispatch({
        type: COURSES_LOADING
    });

    return axios.post(`${process.env.REACT_APP_API_URL}/en/api/events/`, data).then(res => {
        dispatch({
            type: CREATE_COURSE,
            payload: res.data
        });
    }).catch( error => {
        dispatch({
            type: COURSES_LOADING_ERROR,
            error: error.message || error
        });
    });
};

export const uploadCourseImage = (id, formData) => dispatch => {
    dispatch({
        type: COURSES_LOADING
    });

    return axios.post(`${process.env.REACT_APP_API_URL}/en/api/events/${id}/image/`, formData).then(res => {
        const { image, cover } = res.data;
        dispatch({
            type: COURSE_UPLOAD_IMAGE,
            payload: { image, cover }
        });
    }).catch( error => {
        dispatch({
            type: COURSES_LOADING_ERROR,
            error: error.message || error
        });
    });

};

export const updateCourse = (id, data) => dispatch => {
    dispatch({
        type: COURSES_LOADING
    });

    return axios.patch(`${process.env.REACT_APP_API_URL}/en/api/events/${id}/`, data).then(res => {
        dispatch({
            type: CREATE_COURSE,
            payload: res.data
        });
    }).catch( error => {
        dispatch({
            type: COURSES_LOADING_ERROR,
            error: error.message || error
        });
    });
};