import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {PropTypes} from "prop-types";
import {getSchool} from "../redux/actions/SchoolActions";
import {Helmet} from "react-helmet";
import {defaultFonts} from '../fonts';
import WebFont from "webfontloader";
import axios from "axios";
import {withTranslation} from "react-i18next";

const queryString = require('query-string');

class School extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
        }
        this.loadedFonts = [];
        this.advancedLoadedFonts = [];
    }

    componentDidMount() {

        const host = this.getLocation(window.location.href).hostname;
        const rootHost = this.getLocation(process.env.REACT_APP_ROOT_URL).hostname;

        const domain = host.indexOf("." + rootHost) !== -1 ? host.split("." + rootHost)[0] : host;
        this.props.getSchool(domain).then(school => {
            if(school) {

                ['font_base_text', 'font_head', 'font_logo', 'font_menu'].forEach(font => {
                    if (school.design.hasOwnProperty(font) && school.design[font]) {
                        this.loadDefaultFont(school.design[font]);
                    }
                });

                ['url_font_base_text', 'url_font_head', 'url_font_logo'].forEach(url => {
                    if (school.design.hasOwnProperty(url) && school.design[url]) {
                        this.loadFontByUrl(school.design[url]);
                    }
                });

                if(localStorage.getItem('jwt_token')) {
                    axios.post(`${process.env.REACT_APP_API_URL}/api/track/school/${school.school.id}/`, {
                        id: school.school.id
                    }).catch(e => {
                        return null
                    });
                }
            }
            this.setState({loaded: true});
        }).catch(error => {
            this.setState({loaded: true});
        })
    }

    getLocation = (url) => {
        const l = document.createElement("a");
        l.href = url;
        return {
            protocol: l.protocol,
            host: l.host,
            hostname: l.hostname,
            port: l.port,
            pathname: l.pathname,
            hash: l.hash,
            search: l.search,
            origin: l.origin,
        };
    };

    loadFontByUrl = (url) => {
        const parsed = queryString.parseUrl(url);
        if (parsed.query.family) {

            const fontFamily = Array.isArray(parsed.query.family) ? parsed.query.family[0].split(":")[0] : parsed.query.family.split(":")[0];

            if (!this.advancedLoadedFonts.includes(fontFamily)) {
                const css = document.createElement("link");
                css.setAttribute("href", url);
                css.setAttribute("type", "text/css");
                css.setAttribute("rel", "stylesheet");
                document.head.appendChild(css);
            }

            return fontFamily;
        }
        return null;
    }

    loadDefaultFont = (name) => {
        const fontIndex = defaultFonts.findIndex(font => name === font.name);
        if (fontIndex !== -1) {
            if (!this.loadedFonts.includes(defaultFonts[fontIndex].families)) {
                WebFont.load({
                    google: {
                        families: defaultFonts[fontIndex].families
                    }
                });
                this.loadedFonts.push(defaultFonts[fontIndex].families);
            }
        }
    }

    rgba = (hexCode, opacity, fallback = "#cccccc") => {
        if(!hexCode)
            hexCode = fallback;

        let hex = hexCode.replace('#', '');

        if (hex.length === 3) {
            hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
        }

        const r = parseInt(hex.substring(0, 2), 16);
        const g = parseInt(hex.substring(2, 4), 16);
        const b = parseInt(hex.substring(4, 6), 16);

        return `rgba(${r},${g},${b},${opacity / 100})`;
    }


    render() {
        const {children} = this.props;
        const {design} = this.props.school;

        return <Fragment>
            {this.props.school.settings && <Helmet>
                <html lang={this.props.i18n.language || 'en'} translate="no" />
                <title>{this.props.school.settings?.title}</title>
                <style id={"school-colors"}>
                    {`
                        .grain .course-content-sidebar,
                        .grain .colors-sidebar-bg,
                        .school-emenu .school-emenu-list{
                            background-color: ${this.rgba(design.color_bg_menu,100, "#ffffff")} !important;
                        }
                        .grain .jumbotron{ background-color: ${this.rgba(design.color_bg_menu,10, "#cccccc")} }
                        .grain .course-content-sidebar .MuiButton-root,
                        .grain .course-content-sidebar,
                        .grain .topbar, ul.school-emenu-list li, .topbar a, .topbar button
                        {
                            font-family: "${design.font_head || "Roboto"}", sans-serif;
                            color: ${design.color_text_menu || "#000"} !important
                        } 
                        .grain .topbar{
                            box-shadow:1px 1px 5px 0px rgba(0, 0, 0, 0.1);
                        }
                        .grain .school-content-main{
                            background-color: ${design.color_bg_base || "#fff"};
                            font-family: "${design.font_base_text || "Roboto"}", sans-serif;
                            color: ${design.color_text_base || "#000"} !important
                        }
                        .grain .school-content-main .MuiTypography-h6,
                        .grain .school-content-main .MuiTypography-h5,
                        .grain .school-content-main .MuiTypography-h4,
                        .grain .school-content-main .MuiTypography-h3,
                        .grain .school-content-main .MuiTypography-h2,
                        .grain .school-content-main .MuiTypography-h1,
                        .grain .school-content-main .MuiTypography-subtitle1,
                        .grain .school-content-main .MuiTypography-subtitle2
                        {
                            font-family: "${design.font_head || "Roboto"}", sans-serif;
                            color: ${design.color_headers || "#000"} !important
                        }
                        .grain .MuiButton-containedPrimary {
                            background-color: ${design.color_bg_button};
                            color: ${design.color_text_button} !important;
                            font-family: "Roboto", sans-serif;
                        }
                        .grain .MuiButton-containedPrimary:hover {
                            background-color: ${design.color_bg_button};
                            opacity:0.8;
                        }
                        .grain .MuiButton-outlinedPrimary {
                            border-color: ${design.color_bg_button};
                            color: ${design.color_bg_button} !important;
                            font-family: "Roboto", sans-serif;                        
                        }
                        .grain .MuiButton-outlinedPrimary:hover {
                            border-color: ${design.color_bg_button};
                            opacity:0.8;                        
                        }
                        .grain .MuiButton-root.MuiButton-text.link-button{
                            color: ${design.color_bg_button};
                        }
                        .grain .html-content a{
                            color: ${design.color_bg_button};
                            text-decoration: underline;
                        }
                        .grain .html-content a:hover{
                            text-decoration: none;
                        }
                    `}
                </style>
            </Helmet>
            }
            {children}
        </Fragment>;
    }
}

const mapStateToProps = state => ({
    school: state.school,
    getSchool: PropTypes.func.isRequired
})

export default connect(mapStateToProps, {getSchool})( withTranslation()(School));