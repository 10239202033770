export const defaultFonts = [
    {
        name: "PT Sans",
        families: ["PT Sans:400,700"]
    },
    {
        name: "PT Sans Caption",
        families: ["PT Sans Caption:400,700"]
    },
    {
        name: "Roboto",
        families: ["Roboto:400,700"]
    },
    {
        name: "Open Sans",
        families: ["Open Sans:400,700"]
    },
    {
        name: "Oswald",
        families: ["Oswald:200,300"]
    }
];
