import {
    LOAD_SCHOOL,
    SCHOOL_ERROR,
    SCHOOL_LOADING
} from '../actions/SchoolActions';

const initialState = {
    settings: null,
    design: null,
    loading: false,
    error: null
};

const SchoolReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_SCHOOL:
            return {
                loading: false,
                error: null,
                settings: {...action.payload.school},
                design: {...action.payload.design}
            }
        case SCHOOL_LOADING:
            return {
                loading: true,
                error: null,
            }
        case SCHOOL_ERROR:
            return {
                loading: false,
                error: action.payload,
            }
        default:
            return state;
    }
}

export default SchoolReducer;