import {
    LOAD_ALL_COURSES,
    LOAD_COURSE_BY_ID,
    COURSES_LOADING,
    CREATE_COURSE,
    UPDATE_COURSE,
    COURSE_UPLOAD_IMAGE,
    COURSES_LOADING_ERROR
} from '../actions/CourseActions';

const initialState = {
    list: [],
    current: null,
    loading: false,
    error: null,
    currentCategory: null,
};

const CourseReducer = (state = initialState, action) => {
    switch (action.type) {
        case COURSES_LOADING:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case COURSES_LOADING_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            }
        case LOAD_ALL_COURSES:
            return {
                ...state,
                loading: false,
                list: action.payload,
                error: null,
            }
        case LOAD_COURSE_BY_ID:
            return {
                ...state,
                current: { ...action.payload.active_event},
                loading: false,
                error: null,
            }
        case CREATE_COURSE:
            return {
                ...state,
                current: { ...action.payload},
                loading: false,
                error: null,
            }
        case UPDATE_COURSE:
            return {
                ...state,
                current: { ...action.payload},
                loading: false,
                error: null,
            }
        case COURSE_UPLOAD_IMAGE:
            return {
                ...state,
                current: { ...state.current, image: action.payload.image, cover: action.payload.cover },
                loading: false,
                error: null,
            }
        default:
            return state;
    }
}

export default CourseReducer;