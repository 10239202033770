import {
  REGISTER_SUCCESS,
  REGISTER_ERROR,
  REGISTER_LOADING
} from "../actions/RegisterActions";

const initialState = {
  success: false,
  loading: false,
  error: {}
};

const RegisterReducer = function(state = initialState, action) {
  switch (action.type) {
    case REGISTER_LOADING: {
      return {
        ...state,
        loading: true
      };
    }
    case REGISTER_SUCCESS: {
      return {
        ...state,
        success: true,
        loading: false
      };
    }
    case REGISTER_ERROR: {
      return {
        success: false,
        loading: false,
        error: action.error
      };
    }
    default: {
      return state;
    }
  }
};

export default RegisterReducer;
