class Utils
{
    static reorderArray(list, startIndex, endIndex)
    {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    static shuffleArray(a) {
        for (let i = a.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [a[i], a[j]] = [a[j], a[i]];
        }
        return a;
    }

    static getNoun(number, one, two, five) {
        let n = Math.abs(number);
        n %= 100;
        if (n >= 5 && n <= 20) {
            return five;
        }
        n %= 10;
        if (n === 1) {
            return one;
        }
        if (n >= 2 && n <= 4) {
            return two;
        }
        return five;
    }

    static formatAxiosError(error) {
        const data = error.response?.data;
        if (typeof data === 'object' && data !== null) {
            if (data.hasOwnProperty("detail")) {
                return data.detail;
            } else if(data.hasOwnProperty("message")){
                return data.message;
            } else {
                return error.message;
            }
        } else {
            return error.message;
        }
    }

    static formatRemainingRime(date, t)
    {
        let delta = ((new Date(date)) - (new Date())) / 1000;

        if(delta < 0)
            return null;

        let days = Math.floor(delta / 86400);
        delta -= days * 86400;

        if (days > 0)
            return `${days} ` + t('time.days', {
                defaultValue: 'day',
                defaultValue_plural: 'days',
                count: days
            });

        let hours = Math.floor(delta / 3600) % 24;
        delta -= hours * 3600;

        if (hours > 0)
            return `${hours} ` + t('time.hours', {
                defaultValue: 'hour',
                defaultValue_plural: 'hours',
                count: hours
            });

        let minutes = Math.floor(delta / 60) % 60;

        return `${minutes} ` + t('time.minutes', {
            defaultValue: 'minute',
            defaultValue_plural: 'minutes',
            count: minutes
        });
    }

    static getLocation(url){
        const l = document.createElement("a");
        l.href = url;
        return {
            protocol: l.protocol,
            host: l.host,
            hostname: l.hostname,
            port: l.port,
            pathname: l.pathname,
            hash: l.hash,
            search: l.search,
            origin: l.origin,
        };
    };
}

export default Utils;