import {
    CATEGORIES_LOADING,
    LOAD_CATEGORY_BY_ID,
    LOAD_ALL_CATEGORIES,
    LOAD_SUBCATEGORIES
} from '../actions/CategoryActions';

const initialState = {
    items: [],
    current: null,
    loading: false,
    subcategories:[]
};

const CategoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case CATEGORIES_LOADING: {
            return {
                ...state,
                loading: true,
            }
        }
        case LOAD_CATEGORY_BY_ID: {
            return {
                ...state,
                loading: false,
                current: action.payload,
            }
        }
        case LOAD_ALL_CATEGORIES: {
            return {
                ...state,
                loading: false,
                items: action.payload,
            }
        }
        case LOAD_SUBCATEGORIES: {
            return {
                ...state,
                loading: false,
                subcategories: action.payload,
            }
        }
        default:
            return state;
    }
}

export default CategoryReducer;