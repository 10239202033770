import axios from "axios";
import Utils from "../../views/dashboard/components/shared/Utils";
export const SETTINGS_LOADED = "SETTINGS_LOADED";
export const SETTINGS_LOADING = "SETTINGS_LOADING";
export const SETTINGS_ERROR = "SETTINGS_ERROR";
export const SETTINGS_UPDATED = "SETTINGS_UPDATED";

export const loadSettings = (type = "main") => dispatch => {
    dispatch({
        type: SETTINGS_LOADING
    });

    const postfix = "main" === type ? '' : `${type}/settings/`;
    return axios.get(`${process.env.REACT_APP_API_URL}/api/school/${postfix}`).then(response => {
        dispatch({
            type: SETTINGS_LOADED,
            payload: {[type] : response.data}
        });
    }).catch( error => {
        dispatch({
            type: SETTINGS_ERROR,
            error: Utils.formatAxiosError(error)
        });
    });
};