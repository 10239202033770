import {authRoles} from "../../auth/authRoles";
import loadable from '@loadable/component'

const CoursesList = loadable(() => import('./CoursesList'));
const CoursePage = loadable(() => import('./CoursePage'));
const BillingPage = loadable(() => import('./BillingPage'));
const ProcessOrderPage = loadable(() => import('./ProcessOrderPage'));
const CourseView = loadable(() => import('./CourseView'));
const UserPayments = loadable(() => import('./UserPayments'));
const CertificatesList = loadable(() => import('./CertificatesList'));
const CertificatePrint = loadable(() => import('./CertificatePrint'));
const VideoView = loadable(() => import('./VideoView'))

//
// const settings = {
//   activeLayout: "grain",
//   grainSettings: {
//     mode: "full",
//     topbar: {
//       show: true
//     },
//     navbar: { show: false }
//   },
//   secondarySidebar: { show: false },
//   footer: { show: false }
// };

const hideTopbar = {grainSettings: {topbar: {show: false}}};

const routes = [
    {
        path: "/videos/:id(\\d+)",
        component: VideoView,
        auth: authRoles.admin,
        // settings: hideTopbar,
    },
    {
        path: "/courses/view/:courseId(\\d+)/:blockId(\\d+)/:lessonId(\\d+)/:elementId(\\d+)",
        component: CourseView,
        auth: authRoles.admin,
        settings: hideTopbar,
    },
    {
        path: "/courses/view/:courseId(\\d+)/:blockId(\\d+)/:lessonId(\\d+)",
        component: CourseView,
        auth: authRoles.admin,
        settings: hideTopbar,
    },
    {
        path: "/courses/view/:courseId(\\d+)/:blockId(\\d+)",
        component: CourseView,
        auth: authRoles.admin,
        settings: hideTopbar,
    },
    {
        path: "/courses/view/:courseId(\\d+)",
        component: CourseView,
        auth: authRoles.admin,
        settings: hideTopbar,
    },
    {
        path: "/buy/:link/:orderId(\\d+)/process",
        exact: true,
        component: ProcessOrderPage,
        auth: authRoles.admin,
    },
    {
        path: "/buy/:link",
        exact: true,
        component: BillingPage,
        auth: authRoles.admin,
        // settings,
    },

    {
        path: "/certificates",
        exact: true,
        component: CertificatesList,
        auth: authRoles.admin,
    },
    {
        path: "/courses/:courseId(\\d+)",
        exact: true,
        component: CoursePage,
        auth: null,
    },
    {
        path: "/courses",
        exact: true,
        component: CoursesList,
        auth: authRoles.admin,
    },
    {
        path: "/payments",
        exact: true,
        component: UserPayments,
        auth: authRoles.admin,
    },
    {
        path: "/print/cert/:id(\\d+)",
        exact: true,
        settings: hideTopbar,
        component: CertificatePrint,
        auth: authRoles.admin,
    },
    {
        path: "/",
        exact: true,
        component: CoursesList,
        auth: authRoles.admin,
    },
];

export default routes;
