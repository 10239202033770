import axios from "axios";
export const LOAD_ALL_CATEGORIES = "LOAD_ALL_CATEGORIES";
export const LOAD_SUBCATEGORIES = "LOAD_SUBCATEGORIES";
export const CATEGORIES_LOADING = "CATEGORIES_LOADING";
export const LOAD_CATEGORY_BY_ID = "LOAD_CATEGORY_BY_ID";

export const getCategories = (filter = {}) => dispatch => {
    dispatch({
        type: CATEGORIES_LOADING
    });

    return axios.get(`${process.env.REACT_APP_API_URL}/en/api/category/`,{ params: filter}).then(res => {
        dispatch({
            type: LOAD_ALL_CATEGORIES,
            payload: res.data
        });
    });
};

export const getSubcategories = (filter = {}) => dispatch => {
    dispatch({
        type: CATEGORIES_LOADING
    });

    return axios.get(`${process.env.REACT_APP_API_URL}/en/api/category/`,{ params: filter}).then(res => {
        dispatch({
            type: LOAD_SUBCATEGORIES,
            payload: res.data
        });
    });
};


export const getCategory = (id) => dispatch => {
    dispatch({
        type: CATEGORIES_LOADING
    });

    return axios.get(`${process.env.REACT_APP_API_URL}/en/api/category/${id}/`).then(res => {
        dispatch({
            type: LOAD_CATEGORY_BY_ID,
            payload: res.data
        });
    });
};
