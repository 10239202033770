import axios from "axios";

export const LOAD_SCHOOL = "LOAD_SCHOOL";
export const SCHOOL_ERROR = "SCHOOL_ERROR";
export const SCHOOL_LOADING = "SCHOOL_LOADING";

export const getSchool = (domain) => {
    return dispatch => {

        dispatch({
            type: SCHOOL_LOADING
        });

        return axios.get(`${process.env.REACT_APP_API_URL}/api/domain/?name=${encodeURIComponent(domain)}`)
            .then(response => {
                dispatch({
                    type: LOAD_SCHOOL,
                    payload: response.data
                });

                return response.data;
            })
            .catch(error => {
                dispatch({
                    type: SCHOOL_ERROR,
                    payload: error
                })
            });
    }
}
