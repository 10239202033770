import {
    COURSE_BLOCK_LOADING,
    COURSE_BLOCK_ERROR,
    LOAD_COURSE_BLOCK,
    LOAD_ALL_COURSE_BLOCKS,
    ADD_COURSE_BLOCK,
    UPDATE_COURSE_BLOCK,
    DELETE_COURSE_BLOCK
} from '../actions/CourseBlockActions';

const initialState = {
    items: [],
    current: null,
    loading: false,
    error: null,
};

const CourseBlockReducer = (state = initialState, action) => {
    switch (action.type) {
        case COURSE_BLOCK_LOADING: {
            return {
                ...state,
                loading: true,
                error: null,
            }
        }
        case COURSE_BLOCK_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error
            }
        }
        case LOAD_COURSE_BLOCK: {
            return {
                ...state,
                loading: false,
                error: null,
                current: action.payload,
            }
        }
        case ADD_COURSE_BLOCK: {
            return {
                ...state,
                loading: false,
                error: null,
                current: action.payload,
            }
        }
        case UPDATE_COURSE_BLOCK: {
            return {
                ...state,
                loading: false,
                error: null,
                current: action.payload,
            }
        }
        case DELETE_COURSE_BLOCK: {
            return {
                ...state,
                loading: false,
                error: null,
                current: null,
            }
        }
        case LOAD_ALL_COURSE_BLOCKS: {
            return {
                ...state,
                loading: false,
                error: null,
                items: action.payload,
            }
        }
        default:
            return state;
    }
}

export default CourseBlockReducer;