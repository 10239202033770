import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';

i18n
    .use(HttpBackend)
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        cleanCode: true,
        supportedLngs: ['en', 'ru'],
        debug: false,
        keySeparator: '.',
        fallbackLng: false,
        interpolation: {
            escapeValue: false // react already safes from xss
        },
        detection: {
            order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator'],
            lookupQuerystring: 'lng',
            lookupCookie: 'i18n',
            lookupLocalStorage: 'i18nLng',
            lookupFromPathIndex: 0,
            lookupFromSubdomainIndex: 0,
            // cache user language on
            caches: ['localStorage', 'cookie'],
            debug:true,

        },
        backend:{
            loadPath: '/locales/{{lng}}/{{ns}}.json',
        },
        //preload: ['en', 'ru']
    });
//
// i18n.on('languageChanged', function (lng) {
//     localStorage.setItem('_lang', lng);
// });

export default i18n;