import React from "react";
import {Redirect} from "react-router-dom";
import sessionRoutes from "./views/sessions/SessionRoutes";

import schoolRoutes from "./views/school/SchoolRoutes";

const errorRoute = [
    {
        component: () => <Redirect to="/404"/>
    }
];

const routes = [
    ...sessionRoutes,
    ...schoolRoutes,
    ...errorRoute
];

export default routes;
