import NotFound from "./NotFound";

const settings = {
  activeLayout: "layout1",
  layout1Settings: {
    topbar: {
      show: false
    },
    leftSidebar: {
      show: false,
      mode: "close"
    }
  },
  layout2Settings: {
    mode: "full",
    topbar: {
      show: false
    },
    navbar: { show: false }
  },
  secondarySidebar: { show: false },
  footer: { show: false }
};

const sessionRoutes = [
  {
    path: "/signin",
    exact:true,
    component: () => {
      window.location.href = `${process.env.REACT_APP_LOGIN_URL}/?site=${encodeURIComponent(window.location.host)}`;
      return null;
    }
  },
  {
    path: "/404",
    component: NotFound,
    settings
  },
];

export default sessionRoutes;
